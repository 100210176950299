var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
        [
          _c("c-table", {
            ref: "typegrid",
            attrs: {
              title: "대상품명",
              tableId: "typegrid",
              columns: _vm.typegrid.columns,
              data: _vm.typegrid.data,
              usePaging: false,
              hideBottom: true,
              columnSetting: false,
              isFullScreen: false,
              filtering: false,
              isExcelDown: false,
            },
            on: { rowClick: _vm.rowClick },
            scopedSlots: _vm._u([
              {
                key: "suffixTitle",
                fn: function () {
                  return [
                    _vm.editable
                      ? _c(
                          "font",
                          {
                            staticStyle: {
                              "font-size": "0.8em",
                              "font-weight": "300",
                            },
                          },
                          [_vm._v("    [ 행 클릭하세요 ]    ")]
                        )
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10" },
        [
          _c(
            "c-table",
            {
              ref: "grid1",
              attrs: {
                title: "대상품명별 안전검사 검사대상/필수항목",
                topBorderClass: "topcolor-lightblue",
                tableId: "grid1",
                columns: _vm.grid1.columns,
                data: _vm.grid1.data,
                editable: _vm.editable,
                usePaging: false,
                hideBottom: true,
                filtering: false,
                columnSetting: false,
                isExcelDown: false,
                isFullScreen: false,
                selection: "multiple",
                rowKey: "hazardousMachineryInspectionTargetId",
              },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable && _vm.isSelectTgt
                        ? _c("c-btn", {
                            attrs: { label: "LBLADD", icon: "add" },
                            on: { btnClicked: _vm.addRow },
                          })
                        : _vm._e(),
                      _vm.editable && _vm.isSelectTgt
                        ? _c("c-btn", {
                            attrs: { label: "LBLREMOVE", icon: "remove" },
                            on: { btnClicked: _vm.removeRow },
                          })
                        : _vm._e(),
                      _vm.editable && _vm.isSelectTgt
                        ? _c("c-btn", {
                            attrs: { label: "LBLSAVE", icon: "save" },
                            on: { btnClicked: _vm.saveRow },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }